<template>
  <div class="pb-6">
    <LazyUiTabs
      ref="tabsRef"
      :full-width="true"
      :default-tab-title="defaultTab"
    >
      <LazyUiTab title="events">
        <template v-if="tabsRef?.activeTab.toLowerCase() === 'events'">
          <h2 class="text-2xl font-semibold">
            Stake directly on the outcome of events.
          </h2>
          <div class="mt-6">
            <UiPreloader v-if="loading.categories" filter />
            <div v-else-if="!loading.categories && filters" class="relative">
              <div
                v-show="showLeftButton"
                class="scroll-left-wrapper left-0 text-left"
              >
                <button class="scroll-button" @click="scrollFilterLeft">
                  <ArrowLeft class="h-5 w-5" />
                </button>
              </div>
              <div
                v-show="showRightButton"
                class="scroll-right-wrapper right-0 text-right"
              >
                <button class="scroll-button" @click="scrollFilterRight">
                  <ArrowRight class="h-5 w-5" />
                </button>
              </div>
              <div
                ref="scrollContainer"
                class="overflow-x-auto scroll-smooth scroll-filters"
              >
                <div ref="eventPills" class="w-min flex flex-nowrap gap-3">
                  <LazyEventPillFilter
                    v-for="filter in filters"
                    :key="filter.id"
                    v-model="activeFilter"
                    :filter="filter"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
        <EventListing class="mt-10" :category-id="activeFilter.id" />
      </LazyUiTab>
      <LazyUiTab title="history">
        <LazyHistoryView />
      </LazyUiTab>
    </LazyUiTabs>
  </div>
</template>

<script setup lang="ts">
import UiTabs from '@/components/ui/UiTabs.vue'
import type { EventFilter } from '@/types/factoes'
import ArrowRight from '@/assets/icons/chevron-right.svg?component'
import ArrowLeft from '@/assets/icons/chevron-left.svg?component'

const appStore = useAppStore()
const route = useRoute()
const { fetchCategories, filters, loading } = useFactoes()

const activeFilter = ref<EventFilter>({
  option: 'Trending',
  id: '1',
})
const scrollContainer = ref<HTMLDivElement | null>(null)
const eventPills = ref<HTMLDivElement | null>(null)
const showRightButton = ref(false)
const showLeftButton = ref(false)
const scrollDistance = 100
const defaultTab = ref('')
const tabsRef = ref<InstanceType<typeof UiTabs> | null>(null)

if (route.query.tab) {
  defaultTab.value = route.query.tab as string
}

onMounted(() => {
  fetchCategories()
  window.addEventListener('resize', calculateRightButtonVisibility)
})

onUnmounted(() => {
  window.removeEventListener('resize', calculateRightButtonVisibility)
})

function scrollFilterRight() {
  const scroll = scrollContainer.value
  scroll?.scrollBy(scrollDistance, 0)

  const pills = eventPills.value?.offsetWidth
  const scrollWrapper = scrollContainer.value?.offsetWidth
  if (scroll?.scrollLeft !== undefined) {
    showRightButton.value =
      pills! > scrollWrapper! + scroll?.scrollLeft + scrollDistance
    showLeftButton.value = true
  }
}
function scrollFilterLeft() {
  const scroll = scrollContainer.value
  if (scroll?.scrollLeft !== 0) {
    scroll?.scrollBy(-scrollDistance, 0)
    if (scroll?.scrollLeft !== undefined) {
      showLeftButton.value = scrollDistance < scroll?.scrollLeft
      if (scroll?.scrollLeft !== 0) {
        showRightButton.value = scroll?.scrollLeft !== 0
      }
    }
  }
}
function calculateRightButtonVisibility() {
  const pills = eventPills.value?.offsetWidth
  const scrollWrapper = scrollContainer.value?.offsetWidth
  showRightButton.value = pills! > scrollWrapper!
}

watch(filters, async (val) => {
  if (val) {
    await sleep(500)
    calculateRightButtonVisibility()
  }
})

appStore.setPageTitle('Factoes')

definePageMeta({
  layout: 'dashboard',
  middleware: 'auth',
})

useHead({
  title: 'Luckee | Factoes',
})
</script>
