<template>
  <div>
    <UiPreloader v-if="loading.trending" card :count="3" />
    <UiErrorState
      v-else-if="loading.error && !loading.trending"
      @try-again="fetchTrendingEvents"
    />
    <template v-else-if="trendingEvents">
      <UiEmptyState v-if="trendingEvents.length === 0">
        No trending events available
      </UiEmptyState>
      <ul
        v-else
        class="grid grid-cols-1 gap-5 sm:grid-cols-2 xl:grid-cols-3 xl:gap-10 items-stretch"
      >
        <li v-for="(event, index) in trendingEvents" :key="index">
          <nuxt-link :to="`/games/factoes/event/${event._id}`">
            <div
              class="h-full cursor-pointer rounded-lg border border-grey bg-white p-6 shadow-custom transition-colors duration-300 hover:border-primary hover:bg-primary-light/10 dark:border-transparent dark:bg-dark-secondary hover:dark:border-primary/50 dark:hover:bg-primary-light/10"
            >
              <div class="flex items-center justify-between">
                <div
                  class="flex h-12 w-12 items-center justify-center rounded-full bg-primary/20 shadow-custom"
                >
                  <TrendIcon
                    class="h-6 w-6 text-primary-dark dark:text-primary-light"
                  />
                </div>
                <!-- <span v-if="event.userHasStake"
                class="rounded-full bg-purple-500/10 py-1 px-2 text-xs font-bold text-purple-500 shadow-custom">
                Purchased
              </span> -->
              </div>
              <h2 class="mt-5 max-w-[265px] font-bold">
                {{ event.event.title }}
              </h2>
              <p class="mt-1 text-sm text-light-text dark:text-dark-light-text">
                by {{ formatDateNoTime(event.event.endDate) }}
              </p>
              <div class="mt-5 flex items-center">
                <UsersIcon
                  class="h-4 w-4 text-light-text dark:text-dark-light-text"
                />
                <p
                  class="ml-2 text-xs text-light-text dark:text-dark-light-text"
                >
                  {{ formatNumberWithComma(event.count) }}
                </p>
              </div>
            </div>
          </nuxt-link>
        </li>
      </ul>
    </template>
  </div>
</template>

<script setup lang="ts">
import UsersIcon from '@/assets/icons/users.svg?component'
import TrendIcon from '@/assets/icons/trend_up.svg?component'

const { loading, fetchTrendingEvents } = useFactoes()
const { trendingEvents } = storeToRefs(useFactoesStore())

onMounted(() => {
  fetchTrendingEvents()
})
</script>
